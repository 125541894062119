<template>
  <div>
    <v-container>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 pb-2" divider=">"></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Admin</h1>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbs: [
        {
          text: "Admin",
          disabled: true,
        },
      ],
    };
  },
};
</script>